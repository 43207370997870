// .header {
//     position: fixed;
//     z-index: 10;
//     width: 100%;
// }

// .side {
//     position: fixed;
//     top: 10vh
// }

// .main {
//     position: relative;
//     top: 10vh;
// }

/* Add this to your CSS file or Tailwind configuration */
.grid-rows-layout {
    grid-template-rows: auto 1fr; /* Header row and content row */
  }
  
  .grid-cols-layout {
    grid-template-columns: 256px 1fr; /* Sidebar width and content width */
  }
  
  .sidebar {
    grid-row: 2 / 3; /* Place sidebar in the second row */
    grid-column: 1 / 2; /* Place sidebar in the first column */
    height: calc(100vh - 80px); /* Adjust height as needed */
    background-color: #333; /* Sidebar background color */
    z-index: 1000; /* Ensure Sidebar is above other content */
  }
  
  .content {
    grid-row: 2 / 3; /* Place content in the second row */
    grid-column: 2 / 3; /* Place content in the second column */
    overflow: auto; /* Ensure content is scrollable */
  }
  