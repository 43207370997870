.resizer {
    display: inline-block;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: col-resize;
}

.isResizing {
    background-color: #ddd;
}


.contain {
    width: 100%;
    max-width: 90vw;

}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
}


.contain {
    max-width: 100%;
    overflow-x: hidden;
}

img,
iframe,
video {
    max-width: 100%;
    height: auto;
}

table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
}

// upload
.upload-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.upload-page h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.upload-page-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.upload-section {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-section h2 {
    margin-top: 0;
    color: #555;
    font-size: 1.2em;
}

.upload-section p {
    color: #666;
    margin-bottom: 10px;
}

.upload-section .csv-uploader {
    margin-top: 10px;
}


// csv uploader

.csv-uploader-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.csv-uploader-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.csv-uploader-container input[type="file"] {
    margin-bottom: 20px;
}

.csv-uploader-container button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.csv-uploader-container button.preview {
    background-color: #007bff;
}

.csv-uploader-container button.upload {
    background-color: #28a745;
}

.csv-uploader-container button.cancel {
    background-color: #dc3545;
}

.csv-uploader-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.csv-uploader-container table th,
.csv-uploader-container table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.csv-uploader-container table th {
    background-color: #f4f4f4;
    text-align: left;
}